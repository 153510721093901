import { DirectUpload } from '@rails/activestorage'
import $ from 'jquery'

/* The following bubbling JS events fired against the file input:
 *
 * direct_upload:done - when a file has been added and the UI has been updated
 * direct_upload:removed  - when a file has been removed and the UI has been updated
 */

export default class ActiveStorageDirectUploader {
  static filesInProgress = []

  constructor(uploaderInput, fileListDiv) {
    this.fileUploaderTarget = uploaderInput
    this.filesListTarget = fileListDiv
  }

  findOrCreateUlElement = (isImage) => {
    // when an object already has files and we list them, these divs already exist
    // if not, create them, before rendering the new blob previews

    // Sometimes we have multiple forms in the same page that could upload and render their own files (E.g. Conversations, Messages)
    // Sometimes we have multiple file uploaders and lists of files inside the same form (E.g. Worksheet responses/answers)
    // So we should always try to find the active_storage_all_files_wrapper that's the closest to the current file uploader in use
    let wrapperDiv = $(this.fileUploaderTarget)
      .closest('.active_storage_files_wrapper')
      .find('.active_storage_all_files_wrapper')[0]

    if (!wrapperDiv) {
      // if didn't found the rendering list traversing up through its ancestors try to find the closest form and find it inside it
      wrapperDiv = $(this.fileUploaderTarget)
        .closest('form')
        .find('.active_storage_all_files_wrapper')[0]
    }

    if (!wrapperDiv) {
      // if can't find it, create it
      wrapperDiv = document.createElement('div')
      wrapperDiv.classList.add(
        'flex',
        'flex-col',
        'active_storage_all_files_wrapper'
      )

      this.filesListTarget.before(wrapperDiv)
    }

    let ulElement

    if (isImage) {
      ulElement = $(wrapperDiv).find('.active_storage_all_images_wrapper')[0]

      if (!ulElement) {
        ulElement = document.createElement('ul')
        ulElement.classList.add(
          'active_storage_all_images_wrapper',
          'flex',
          'flex-wrap',
          'gap-2',
          'items-center',
          'p-2'
        )
        wrapperDiv.appendChild(ulElement)
      }
    } else {
      ulElement = $(wrapperDiv).find(
        '.active_storage_all_non_images_wrapper'
      )[0]
      if (!ulElement) {
        ulElement = document.createElement('ul')
        ulElement.classList.add(
          'active_storage_all_non_images_wrapper',
          'p-2',
          'flex',
          'flex-col',
          'items-start',
          'gap-1',
          'text-primary-500',
          'cursor-pointer'
        )
        wrapperDiv.appendChild(ulElement)
      }
    }
    return ulElement
  }

  renderRemoveButtonForBlobPreview(link, blob_data) {
    const { content_type, signed_id } = blob_data
    const isImage = content_type.startsWith('image')

    const btn = document.createElement('p')
    btn.innerHTML = 'x'

    if (isImage) {
      // make the X be the white circle on right upper corner of image
      btn.classList.add(
        'active-storage-file-delete-btn',
        'flex',
        'items-center',
        'justify-center',
        'text-sm',
        'text-center',
        'absolute',
        '-right-1',
        '-top-1',
        'font-bold',
        'text-primary-500',
        'cursor-pointer',
        'w-5',
        'h-5',
        'bg-white',
        'border',
        'border-gray-400',
        'rounded-full'
      )
    } else {
      // make the X be a simple X next to filename
      btn.classList.add(
        'active-storage-file-delete-btn',
        'font-bold',
        'text-primary-500',
        'cursor-pointer'
      )
    }

    btn.addEventListener('click', () => {
      const blob = document.getElementById(`blob-preview-${signed_id}`)
      const hidden_field = document.getElementById(
        `blob-hidden-field-${signed_id}`
      )
      blob.remove()
      hidden_field.remove()
      this.fileUploaderTarget.dispatchEvent(
        new Event('direct_upload:removed', { bubbles: true })
      )
    })

    return btn
  }

  renderBlobPreview(blob_data) {
    const { filename, content_type, url, signed_id, size } = blob_data
    const isImage = content_type.startsWith('image')

    const ulElement = this.findOrCreateUlElement(isImage)

    const liElement = document.createElement('li')
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('target', '_blank')

    if (isImage) {
      liElement.classList.add(
        'active_storage_image_wrapper',
        'gap-1',
        'items-center',
        'relative',
        'active_storage_file'
      )

      link.classList.add('max-w-64', 'max-h-48')
      link.innerHTML = `<img src="${url}" class="max-w-64 max-h-48 text-primary-500 border border-gray-400 image_file">`
    } else {
      link.classList.add('text-primary-500')
      link.innerHTML = filename

      liElement.classList.add(
        'active_storage_non_image_wrapper',
        'flex',
        'gap-2',
        'items-center',
        'active_storage_file'
      )
    }

    liElement.setAttribute('id', 'blob-preview-' + signed_id)
    liElement.setAttribute('data-size', size)
    liElement.appendChild(link)
    liElement.appendChild(
      this.renderRemoveButtonForBlobPreview(link, blob_data)
    )
    const fileIndexToDelete =
      ActiveStorageDirectUploader.filesInProgress.findIndex(
        (fileElement) => fileElement.name === filename
      )
    if (fileIndexToDelete !== -1) {
      ActiveStorageDirectUploader.filesInProgress.splice(fileIndexToDelete, 1)
    }
    ulElement.appendChild(liElement)

    this.fileUploaderTarget.dispatchEvent(
      new Event('direct_upload:done', { bubbles: true })
    )
  }

  fetchAndRenderBlobPreview(blob) {
    $.ajax({
      type: 'GET',
      context: this,
      url: '/blobs/preview_blob_data/' + blob.signed_id,
      data: {},
      success: function (data) {
        this.renderBlobPreview(data)
      },
      error: function (xhr, error, _message) {
        throw new Error(`Direct upload failed: ${error}`)
      },
    })
  }

  createAttachableRecord(url, field_name, value) {
    $.ajax({
      url: url,
      type: 'POST',
      data: { [field_name]: value },
      success: function (data) {
        // Append the new message to the conversation
        window.AjaxHelper.doUpdate(data)
      },
      error: function (xhr) {
        // Handle error
        console.log('Error uploading file', xhr)
      },
    })
  }

  uploadFile(file, url, directPostPath = false) {
    // form needs the file_field direct_upload: true, which
    //  provides data-direct-upload-url
    const upload = new DirectUpload(file, url, this)

    upload.create((error, blob) => {
      if (error) {
        // Handle the error. TODO.
      } else {
        // Add an appropriately-named hidden input to the form with a
        //  value of blob.signed_id so that the blob ids will be
        //  transmitted in the normal upload flow
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute('id', 'blob-hidden-field-' + blob.signed_id)
        hiddenField.setAttribute('type', 'hidden')
        hiddenField.setAttribute('value', blob.signed_id)
        hiddenField.name = this.fileUploaderTarget.name
        this.fileUploaderTarget.closest('form').appendChild(hiddenField)

        if (directPostPath) {
          this.createAttachableRecord(
            directPostPath,
            this.fileUploaderTarget.name,
            blob.signed_id
          )
        } else {
          // render blob preview with button to delete it as well
          this.fetchAndRenderBlobPreview(blob)
        }
      }
    })
  }

  handleFilesUpload() {
    const files = Array.from(this.fileUploaderTarget.files)
    const url = this.fileUploaderTarget.dataset.directUploadUrl
    const directPostPath = this.fileUploaderTarget.dataset.directPostPath

    this.filesListTarget.innerHTML = 'Uploading...'

    files.forEach((file) => {
      ActiveStorageDirectUploader.filesInProgress.push({
        name: file.name,
        size: file.size,
        uploaded: 0,
      })
      this.uploadFile(file, url, directPostPath)
    })

    // prevent files duplication
    this.fileUploaderTarget.value = ''
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', (event) => {
      this.progressUpdate(event)
    })
  }

  progressUpdate(event) {
    ActiveStorageDirectUploader.filesInProgress.forEach((uploadingFile) => {
      if (uploadingFile.size === event.total) {
        uploadingFile.uploaded = event.loaded
      }
    })

    const progress = ActiveStorageDirectUploader.totalProgressPercentage()

    const submitButton = $(this.fileUploaderTarget)
      .closest('form')
      .find('input[type=submit]')
    if (progress === 100) {
      this.filesListTarget.innerHTML = ''
      // prevent user from submiting
      if (submitButton) {
        submitButton.prop('disabled', false)
      }
    } else {
      this.filesListTarget.innerHTML =
        'Uploading... ' + progress.toFixed(2) + '%'
      if (submitButton) {
        submitButton.prop('disabled', true)
      }
    }
  }

  static inProgressTotalSize() {
    return ActiveStorageDirectUploader.filesInProgress.reduce(
      (totalSize, element) => totalSize + element.size,
      0
    )
  }

  static totalProgressPercentage() {
    const uploadedSize = ActiveStorageDirectUploader.filesInProgress.reduce(
      (uploadedTotal, element) => uploadedTotal + element.uploaded,
      0
    )
    return (
      (uploadedSize / ActiveStorageDirectUploader.inProgressTotalSize()) * 100
    )
  }
}
